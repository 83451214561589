// TailwindCSS colors

export const white = '#ffffff'
export const black = '#000000'

/* --- Slate --- */
export const slate50 = '#f8fafc'
export const slate100 = '#f1f5f9'
export const slate200 = '#e2e8f0'
export const slate300 = '#cbd5e1'
export const slate400 = '#94a3b8'
export const slate500 = '#64748b'
export const slate600 = '#475569'
export const slate700 = '#334155'
export const slate800 = '#1e293b'
export const slate900 = '#0f172a'
export const slate950 = '#020617'

/* --- Gray --- */
export const gray50 = '#f9fafb'
export const gray100 = '#f3f4f6'
export const gray200 = '#e5e7eb'
export const gray300 = '#d4d4d8'
export const gray400 = '#9ca3af'
export const gray500 = '#6b7280'
export const gray600 = '#4b5563'
export const gray700 = '#374151'
export const gray800 = '#1f2937'
export const gray900 = '#111827'
export const gray950 = '#030712'

/* --- Red --- */
export const red50 = '#fef2f2'
export const red100 = '#fee2e2'
export const red200 = '#fecaca'
export const red300 = '#fca5a5'
export const red400 = '#f87171'
export const red500 = '#ef4444'
export const red600 = '#dc2626'
export const red700 = '#b91c1c'
export const red800 = '#991b1b'
export const red900 = '#7f1d1d'
export const red950 = '#450a0a'

/* --- Yellow --- */
export const yellow50 = '#fefce8'
export const yellow100 = '#fef9c3'
export const yellow200 = '#fef08a'
export const yellow300 = '#fde047'
export const yellow400 = '#facc15'
export const yellow500 = '#f59e0b'
export const yellow600 = '#d97706'
export const yellow700 = '#b45309'
export const yellow800 = '#92400e'
export const yellow900 = '#78350f'
export const yellow950 = '#422006'

/* --- Green --- */
export const green50 = '#ecfdf5'
export const green100 = '#d1fae5'
export const green200 = '#a7f3d0'
export const green300 = '#6ee7b7'
export const green400 = '#34d399'
export const green500 = '#22c55e'
export const green600 = '#16a34a'
export const green700 = '#15803d'
export const green800 = '#166534'
export const green900 = '#14532d'
export const green950 = '#052e16'

/* --- Blue --- */
export const blue50 = '#eff6ff'
export const blue100 = '#dbeafe'
export const blue200 = '#bfdbfe'
export const blue300 = '#93c5fd'
export const blue400 = '#60a5fa'
export const blue500 = '#3b82f6'
export const blue600 = '#2563eb'
export const blue700 = '#1d4ed8'
export const blue800 = '#1e40af'
export const blue900 = '#1e3a8a'
export const blue950 = '#172554'
